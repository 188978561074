import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue"
import NotFound from "../views/NotFound.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/404'
  },

  {
    path: '/404',
    component: NotFound
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: "/voucher/:code",
    name: "home",
    component: Home
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;