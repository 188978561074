<template>
  <div class="flex flex-col min-h-screen">
    <nav class="bg-[#1f74ff] mb-5 shadow-md">
      <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 flex items-center justify-center h-16">
        <div class="flex-shrink-0">
          <h1 class="text-white text-2xl">Siceron App</h1>
        </div>
      </div>
    </nav>
    <section class="flex-grow">
      <div>
        <img :src="couponImage" alt="coupon" class="w-56 mx-auto">
      </div>
      <div v-if="Object.keys(data).length !== 0">
        <h1 class="font-bold text-2xl">{{ data.name }}</h1>
        <div class="w-full px-5 my-5">
          <div class="rounded-lg overflow-hidden shadow-md">
            <table class="table- w-full border-collapse border-0 shadow-xl">
              <thead>
                <tr class="text-lg font-semibold text-white bg-blue-400">
                  <th class="px-4 py-2 text-center">Code</th>
                  <th class="px-4 py-2 text-center">Status</th>
                </tr>
              </thead>
              <tbody class="text-gray-600 text-sm font-light">
                <tr class="border-b border-gray-200 hover:bg-gray-100">
                  <td class="px-4 py-2">{{ data.code }}</td>
                  <td class="px-4 py-2 flex justify-center">
                    <vs-chip :color="data.status == 0 ? 'warning' : 'success'" class="font-bold">
                      {{ data.status == 0 ? 'INVALID' : 'VALID' }}
                    </vs-chip>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <vs-button v-show="data.status != 0" color="primary" type="filled" class="px-3" @click="confirmApply(data.id)">APPLY</vs-button>
      </div>
      <div v-else>
        <h2 class="mt-4 text-md">Maaf, kode voucher tidak ditemukan!</h2>
      </div>
    </section>
    <footer class="py-4 text-center">
      <p class="text-sm">&copy; 2023 Siceron App. All Rights Reserved.</p>
    </footer>
  </div>
</template>

<script>
import axios from "@/axios.js";
import couponImage from "../assets/img/coupon.png"

export default {
  name: "LandingView",

  data() {
    return {
      couponImage: couponImage,
      code: this.$route.params.code,
      data: {},
    };
  },

  mounted() {
    this.getVoucher();
    this.$vs.loading()
  },

  methods: {
    getVoucher() {
      const code = this.$route.params.code
      axios
        .get(`voucher/${code}`)
        .then(({ data: res }) => {
          if (res.code_status === 200) {
            this.data = res.data;
          }
        })
        .catch(({ response: res }) => {
          this.$vs.notify({
            color: "danger",
            position: "bottom-center",
            title: "Oopps...", 
            text: res.data.msg_status,
          });
        })
        .finally(() => {
           this.$vs.loading.close()
        });
    },
    confirmApply(voucherID) {
      this.$vs.dialog({
        type: "confirm",
        color: "warning",
        title: `Konfirmasi`,
        text: `Apakah Anda yakin akan menggunakan voucher ini?`,
        accept: () => {
          this.applyVoucher(voucherID)
        },
        acceptText: "Yes"
      });
    },
    applyVoucher(voucherID) {
      const body = {
        status: 0
      }
      if(this.data.status == 1) {
        axios.put(`voucher/${voucherID}`, body)
        .then(({ data: res }) => {
          this.$vs.notify({
            color: "success",
            position: "bottom-center",
            title: 'Success',
            text: res.msg_status,
          });
          this.getVoucher();
        })
        .catch(({ response: res }) => {
          this.$vs.notify({
            color: "danger",
            position: "bottom-center",
            title: 'Oopps...',
            text: res.msg_status,
          });
        });
      } else {
        this.$vs.notify({
          color: "danger",
          position: "bottom-center",
          title: 'Oopps...',
          text: "Voucher ini sudah di gunakan!",
        });
      }
    },
  },
};
</script>