<template>
  <div>
    <div class="flex items-center justify-center h-screen">
      <div class="text-center">
        <h1 class="text-4xl font-bold mb-4">404</h1>
        <p class="text-xl mb-4">Maaf, halaman tidak ditemukan.</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotFound',
}
</script>