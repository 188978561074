import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import "./index.css";

Vue.config.productionTip = false

// Vuesax
import Vuesax from "vuesax";

import "vuesax/dist/vuesax.css";
Vue.use(Vuesax, {
  // options here
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
